import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import firebase from 'firebase/app';
import store from './store'
import { VuePlausible } from 'vue-plausible'
// import VueGtag from "vue-gtag";

import './components/scripts/firebase-init';
import './registerServiceWorker'
import i18n from './i18n.js'

Vue.use(VueMeta)

Vue.config.productionTip = false

Vue.use(VuePlausible, {
  domain: "chachi.app",
  hashMode: true
})
Vue.$plausible.enableAutoPageviews()
// Vue.$plausible.enableAutoOutboundTracking()

// Vue.use(VueGtag, {
//   config: { id: "UA-54006622-3" }
// });

let app;
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }
});