<template>
    <div class="position-relative" style="z-index: 5">
        <!-- <div class="toast-container position-absolute p-2 mt-2 bottom-0 start-50 translate-middle-x align-items-center br-8"> -->
        <div class="toast-container position-absolute top-0 end-0 px-3 br-8">
            <div id="liveToastPrimary" class="toast hide" role="status" aria-live="polite" aria-atomic="true" data-bs-autohide="true" data-bs-delay="2500">
                <div class="d-flex text-white">
                    <div class="toast-body" id="toast-message-primary"></div>
                    <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
            <div id="liveToastWarning" class="toast hide" role="status" aria-live="polite" aria-atomic="true" data-bs-autohide="true" data-bs-delay="2500">
                <div class="d-flex text-dark">
                    <div class="toast-body" id="toast-message-warning"></div>
                    <button type="button" class="btn-close btn-close-dark me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "toast"
}
</script>

<style scoped>
#liveToastPrimary {
    background-color: var(--bs-primary);
}
#liveToastWarning {
    background-color: var(--bs-warning);
}
#toast-message {
    font-size: 1.1em;
}
</style>