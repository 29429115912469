import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import axios from 'axios'
import firebase from 'firebase/app';
import i18n from '../i18n.js'

Vue.use(Vuex)
Vue.use(VueCookies)

const state = () => ({
    lang: Vue.$cookies.get('currentLang'),
    a2hs: false,
    bookmarkCards: [],
    currentUser: {},
    isLoggedIn: false,
    // cards: [{"subject": "SUBJECT", "en": "### Get ready!", 'hi':"### तैयार हो जाइए!"},
    // {"subject": "SUBJECT", "en": "### Preparing some exciting content for you!", 'hi':"### आप के लिए कुछ मज़ेदार बना रहे हैं!"}],
    cards: [],
    category: '',
    categoryValue: '',
    currentCardIndex: -1
})

const mutations = {
    setCurrentUser(state, payload) {
      Vue.set(state.currentUser, 'uid', payload.uid)
      Vue.set(state.currentUser, 'firstName', payload.firstName)
      Vue.set(state.currentUser, 'lastName', payload.lastName)
      Vue.set(state.currentUser, 'email', payload.email)
      Vue.set(state.currentUser, 'profession', payload.profession)
      Vue.set(state.currentUser, 'state', payload.state)
      Vue.set(state.currentUser, 'proUser', payload.proUser)
      Vue.set(state.currentUser, 'preferredLanguage', payload.preferredLanguage)
      if (payload.bookmarks[0] === "null") { Vue.set(state.currentUser, 'bookmarks', []) }
      else {Vue.set(state.currentUser, 'bookmarks', payload.bookmarks)}
      state.isLoggedIn = true
      // }
    },
    updateLocalUserBookmarks(state, payload) {
      var currentBookmarks = state.currentUser.bookmarks
      if (payload[0]) { // if bookstate | adding
        currentBookmarks.push(payload[1])
      } else { // if not bookstate | removing
        var bookmarkIndex = currentBookmarks.indexOf(payload[1])
        currentBookmarks.splice(bookmarkIndex, 1)
      }
      Vue.set(state.currentUser, 'bookmarks', currentBookmarks)
    },
    setUserJWT(state, payload) {
      Vue.$cookies.set('user-jwt', payload['token']);
    },
    setBookmarkCards(state, payload) {
      state.bookmarkCards = payload
    },
    addCards(state, payload) {
      state.cards.push(payload)
    },
    setCards(state, payload) {
      state.cards = payload
    },
    setCardIndex(state, payload) {
      state.currentCardIndex = payload
    },
    setLang(state, langcode) {
      state.lang = langcode;
      Vue.$cookies.set('currentLang', langcode);
      i18n.locale = langcode
      // router.push('stack')
      // if (this.$route.name != 'main') {}
      // router.go('stack')
    },
    setLocale() {
      i18n.locale = Vue.$cookies.get('currentLang');
    },
    setCategory(state, input) {
      state.category = input
    },
    setCategoryValue(state, input) {
      state.categoryValue = input
    },
    a2hs(state) {
      Vue.$cookies.set('a2hs', true, '7d')
      state.a2hs = true
    }
}

const actions = {
  setCurrentUser ({ commit }) {
    if (firebase.auth().currentUser) {
      // fetch user details from DB
      const token = Vue.$cookies.get('user-jwt')
      const config = { headers: { "Authorization": `Bearer ${token}`, "Access-Control-Expose-Headers": "Authorization" } };
      var api_link = 'https://api.chachi.app/v2/users/fetch?user=' + encodeURI(firebase.auth().currentUser.uid)
      axios.get(api_link, config)
        .then( response => {
          commit('setCurrentUser', response.data[0])
          return true 
        })
        .catch (err => {
          console.log(err)
          if (err.response.data.detail === "Invalid token or expired token.") {
            Vue.$store.dispatch('setUserJWT')
          }
        })
    } else {
      console.log('firebase not logged in')
      return true
    }
  },
  async setUserJWT ({ commit }) {
    let idToken;
    await firebase.auth().currentUser.getIdToken(true)
      .then(res => idToken = res);
    commit('setUserJWT', {'token': idToken})
    console.log('new token set')
    return true 
  },
  updateLocalUserBookmarks( { commit }, payload) {
    commit('updateLocalUserBookmarks', payload)
  },
  setBookmarkCards ( { commit }, payload) {
    commit('setBookmarkCards', payload)
  }
}
// const getters = {
//     isLoggedIn: state => {
//       return state.isLoggedIn
//     }
// }

var ls = new SecureLS({ encodingType: 'aes', isCompression: false });

export default new Vuex.Store({
    plugins: [createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
      paths: ['isLoggedIn', 'currentUser', 'lang'],
    })],
    state,
    mutations,
    actions
})