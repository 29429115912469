import Vue from 'vue'
import VueRouter from 'vue-router'
// import firebase from 'firebase';
import firebase from 'firebase/app';

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: '404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../pages/404.vue'),
  },
  {
    path: '/card-*',
    // meta: {requiresAuth: true},
    component: () => import('../components/card/Activity.vue')
  },
  {
    path: '/',
    name: 'Landing',
    component: () => import('../views/Landing.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../pages/About.vue'),
  },
  {
    path: '/acknowledgements',
    name: 'Acknowledgements',
    component: () => import('../pages/Acknowledgements.vue'),
  },
  {
    path: '/bookmarks',
    name: 'Bookmarks',
    meta: {requiresAuth: true},
    component: () => import('../components/card/Bookmarks.vue'),
  },
  // {
  //   path: '/card',
  //   name: 'Card',
  //   meta: {requiresAuth: true},
  //   component: () => import('../components/card/FullCard.vue'),
  // },
  {
    path: '/coming-soon',
    name: 'Coming Soon ',
    component: () => import('../pages/ComingSoon.vue'),
  },
  {
    path: '/contribute',
    name: 'Contribute',
    component: () => import('../pages/Contribute.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../pages/Contact.vue'),
  },
  {
    path: '/diary',
    name: 'Diary',
    meta: {requiresAuth: true},
    component: () => import('../views/Diary.vue'),
  },
  {
    path: '/how-to',
    name: 'How To',
    component: () => import('../pages/HowTo.vue'),
  },
  {
    path: '/install',
    name: 'Install',
    component: () => import('../pages/Install.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../pages/Login.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import('../pages/Privacy-Policy.vue'),
  },
  {
    path: '/premium',
    name: 'Premium Subscription',
    component: () => import('../pages/Pro-Signup.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../pages/Register.vue'),
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {requiresAuth: true},
    component: () => import('../views/Settings.vue'),
  },
]

// Scroll to top on load
const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: 'smooth'
        }
      }
      return { x: 0, y: 0, behavior: 'smooth' }
    }
  }
})

// Nav Guard
router.beforeEach((to, from, next) => {
  // Check for requiresAuth guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if NO logged user
    if (!firebase.auth().currentUser) {
      // Go to login
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      // Proceed to route
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    // Check if NO logged user
    if (firebase.auth().currentUser) {
      // Go to login
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      // Proceed to route
      next();
    }
  } else {
    // Proceed to route
    next();
  }
});


export default router
