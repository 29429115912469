<template>
  <div id="app">
    <!-- Add Key that checks user login state change -->
    <Navigation :key="$store.state.isLoggedIn" />
    <main class="br-16" style="padding-bottom: 5rem;">
      <toast/>
      <button id='lang-selector' class='text-muted btn mx-auto p-2' @click="updateLang()" tabindex="0">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-translate" viewBox="0 0 16 16"><path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z"/><path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z"/></svg> {{ $t('lang_button') }}
      </button>
      <transition name="router-animation">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </main>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/layout/Navigation';
import Footer from '@/layout/Footer';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@/assets/style.css'
import Toast from './components/Toast.vue';

// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'

export default {
  name: 'App',
  metaInfo: {
    title: 'Chachi',
    titleTemplate: '%s – Wikipedia for Teaching'
  },
  components: {
    Navigation,
    Toast,
    Footer
  },
  mounted() {
    // this.pushMetaTags()
    // let plausible = document.createElement('script')
    // plausible.defer = true
    // plausible.setAttribute('src', 'https://plausible.io/js/plausible.js')
    // plausible.setAttribute('data-domain', 'chachi.app')
    // document.head.appendChild(plausible)

    // let goatcounter = document.createElement('script')
    // goatcounter.async = true
    // goatcounter.setAttribute('src', '//gc.zgo.at/count.js')
    // goatcounter.setAttribute('data-goatcounter', 'https://chachi.goatcounter.com/count')
    // document.head.appendChild(goatcounter)

    // set language if not set
    if (this.$store.state.lang === null) { this.$store.commit("setLang", 'en') }
    // manually set i18n lang
    this.$store.commit('setLocale')
  },
  methods: {
    updateLang() {
      this.$plausible.trackEvent('setLanguage')
      
      if (this.$store.state.lang === 'en') {
        this.$store.commit("setLang", 'hi')
      }
      else if (this.$store.state.lang === 'hi') {
        this.$store.commit("setLang", 'en')
      }
      window.location.reload()
    },
    pushMetaTags() {
      let properties = ['og:description', 'twitter:description', 'og:url', 'og:type', 'og:title', 'og:site_name',
      'twitter:domain', 'twitter:url', 'twitter:title', 'image', 'og:image', 'twitter:image', 'twitter:image:src']
      let contentValue = {
        'og:description': 'A repository of best practices and conversation triggers for engaging learners in experiential learning.',
        'twitter:description': 'A repository of best practices and conversation triggers for engaging learners in experiential learning.',
        'og:url':'https://chachi.app',
        'og:type':'website',
        'og:title':'Chachi – Wikipedia for Teaching',
        'og:site_name': 'Chachi',
        'twitter:domain': 'chachi.app',
        'twitter:url': 'https://chachi.app',
        'twitter:title': 'Chachi – Wikipedia for Teaching',
        'image': 'https://thatgurjot.com/Chachi-Flat-Banner.png',
        'og:image': 'https://thatgurjot.com/Chachi-Flat-Banner.png',
        'twitter:image': 'https://thatgurjot.com/Chachi-Flat-Banner.png',
        'twitter:image:src': 'https://thatgurjot.com/Chachi-Flat-Banner.png'
      }
      for (var i=0; i < properties.length; i++) {
        var tag = document.createElement('meta')
        tag.setAttribute('property', properties[i])
        tag.setAttribute('content', contentValue[properties[i]])
        document.head.appendChild(tag)
      }

      var propnames = ['description', 'author', 'robots']
      var propValues = {
        'description': 'A repository of best practices and conversation triggers for engaging learners in experiential learning.',
        'author': 'Gurjot.',
        'robots': 'index,follow'
      }
      for (var j=0; j < propnames.length; j++) {
        var tag2 = document.createElement('meta')
        tag2.setAttribute('name', propnames[j])
        tag2.setAttribute('content', propValues[propnames[j]])
        document.head.appendChild(tag2)
      }
    }
  }
}
</script>

<style>

#app {
  font-family: 'Mukta', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.btn.disabled, .btn:disabled {
	opacity: .65;
}
</style>
