<template>
  <nav class="navbar navbar-expand-md navbar-light shadow-sm">
    <div class="container-fluid">
      <router-link to="/">
        <span class="navbar-brand text-dark">
          <!-- <img class="mb-2" src="@/assets/img/chachi-flat.png" alt="Chachi logo" width="90"> -->
          <span class="text-dark"><mark><strong>{{ $t('chachi') }}</strong></mark></span>
          <sup v-if="currentUser.proUser === true" class="pro-tag-nav">PRO</sup>
          <!-- <span id="nav-brand"></span> -->
        </span>
      </router-link>
      <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse" id="navbarCollapse" style="">
        <ul class="navbar-nav ms-auto mb-2 mb-md-0">
          <li class="nav-item active">
            <router-link to="/" v-if="!isLoggedIn">
              <span class="nav-link text-dark" id="nav-home">{{ $t('home') }}</span>
            </router-link>
            <router-link to="/search" v-if="isLoggedIn">
              <span class="nav-link text-dark" id="nav-home">{{ $t('home') }}</span>
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-dark" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false"> {{ $t('about') }} </a>
            <ul class="dropdown-menu" aria-labelledby="dropdown01">
              <router-link to="/how-to" id='nav-how-to' class="underline dropdown-item text-dark"> {{ $t('about-how-to') }} </router-link>
              <router-link to="/how-to#chachi-features" id='nav-features' class="underline dropdown-item text-dark"> {{ $t('about-features') }} </router-link>
              <router-link to="/about" id='nav-about-us' class="underline dropdown-item text-dark"> {{ $t('about-us') }} </router-link>
            </ul>
          </li>
          <!-- <li class="nav-item">
            <a id="nav-blog-link" class="nav-link text-dark" href="https://blog.chachi.app/" target="_blank" rel="noopener">{{ $t('blog') }}</a>
          </li> -->
          <li v-if="!isLoggedIn" class="nav-item active">
            <router-link to="/login">
              <span class="nav-link text-dark" id="nav-login"> {{ $t('login') }} </span>
            </router-link>
          </li>
          <li v-if="isLoggedIn" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-dark" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false">{{ currentUser.firstName }}<sup v-if="currentUser.proUser === true" class="pro-tag-nav">PRO</sup></a>
            <ul class="dropdown-menu" aria-labelledby="dropdown04">
              <router-link to="/diary" id='nav-user-diary' class="underline dropdown-item text-dark"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-journal-bookmark" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M6 8V1h1v6.117L8.743 6.07a.5.5 0 0 1 .514 0L11 7.117V1h1v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8z"/><path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/><path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/></svg> {{ $t('diary') }} </router-link>
              <router-link to="/settings" id='nav-user-settings' class="underline dropdown-item text-dark"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16"><path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/></svg> {{ $t('profile') }} </router-link>
              <span v-on:click="logout" id='nav-user-logout' class="underline dropdown-item text-dark" style="cursor: pointer;" href="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-door-closed" viewBox="0 0 16 16"><path d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2zm1 13h8V2H4v13z"/><path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"/></svg> {{ $t('logout') }} </span>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
// import firebase from 'firebase';
import firebase from 'firebase/app';
import { mapState } from 'vuex'

export default {
    name: 'Navigation',
    components: {
    },
    data() {
      return {
      };
    },
    computed: mapState({
      isLoggedIn: state => state.isLoggedIn,
      currentUser: state => state.currentUser
    }),
    created() {
    },
    methods: {
      logout: function() {
        firebase
          .auth()
          .signOut()
          .then(() => {
            localStorage.removeItem('vuex')
            this.$router.go({ path: this.$router.path });
          });
      }
    }
}
</script>

<style scoped>
.navbar {
  background-color: transparent;
}
#nav {
  font-size: x-large;
  background-color: #eeeeee60;
  border: 1px dotted #ddd;
  width: 300px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

a, a:hover {
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
#nav-brand {
  font-family: 'Playfair Display';
  font-size: 1.75rem;
  background-color: rgb(255, 249, 82);
}

/* to fix dropdown overflow and keep font-size consistent */
.dropdown-menu {
  right: 0;
  left: auto;
  font-size: 1.125rem;
}
</style>